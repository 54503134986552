import { default as account_45deleteSHQfcrItbeMeta } from "/app/pages/account-delete.vue?macro=true";
import { default as careersb3LI3USwU5Meta } from "/app/pages/careers.vue?macro=true";
import { default as contactV6oPJjs9RyMeta } from "/app/pages/contact.vue?macro=true";
import { default as indexN6pT4Un8hYMeta } from "/app/pages/index.vue?macro=true";
import { default as privacyg3gwBRGXatMeta } from "/app/pages/privacy.vue?macro=true";
import { default as return_45policyFD37NE0ujqMeta } from "/app/pages/return-policy.vue?macro=true";
import { default as termsy18ZESLqu6Meta } from "/app/pages/terms.vue?macro=true";
export default [
  {
    name: "account-delete___ar",
    path: "/ar/account-delete",
    component: () => import("/app/pages/account-delete.vue").then(m => m.default || m)
  },
  {
    name: "account-delete___en",
    path: "/account-delete",
    component: () => import("/app/pages/account-delete.vue").then(m => m.default || m)
  },
  {
    name: "careers___ar",
    path: "/ar/careers",
    component: () => import("/app/pages/careers.vue").then(m => m.default || m)
  },
  {
    name: "careers___en",
    path: "/careers",
    component: () => import("/app/pages/careers.vue").then(m => m.default || m)
  },
  {
    name: "contact___ar",
    path: "/ar/contact",
    component: () => import("/app/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: "contact___en",
    path: "/contact",
    component: () => import("/app/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: "index___ar",
    path: "/ar",
    meta: indexN6pT4Un8hYMeta || {},
    component: () => import("/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___en",
    path: "/",
    meta: indexN6pT4Un8hYMeta || {},
    component: () => import("/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "privacy___ar",
    path: "/ar/privacy",
    component: () => import("/app/pages/privacy.vue").then(m => m.default || m)
  },
  {
    name: "privacy___en",
    path: "/privacy",
    component: () => import("/app/pages/privacy.vue").then(m => m.default || m)
  },
  {
    name: "return-policy___ar",
    path: "/ar/return-policy",
    component: () => import("/app/pages/return-policy.vue").then(m => m.default || m)
  },
  {
    name: "return-policy___en",
    path: "/return-policy",
    component: () => import("/app/pages/return-policy.vue").then(m => m.default || m)
  },
  {
    name: "terms___ar",
    path: "/ar/terms",
    component: () => import("/app/pages/terms.vue").then(m => m.default || m)
  },
  {
    name: "terms___en",
    path: "/terms",
    component: () => import("/app/pages/terms.vue").then(m => m.default || m)
  }
]