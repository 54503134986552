import PrimeVue from 'primevue/config';
// import Button from 'primevue/button';
import Sidebar from 'primevue/sidebar';
import Divider from 'primevue/divider';
import Password from 'primevue/password';
import ToastService from 'primevue/toastservice';
import Toast from 'primevue/toast';
import Button from 'primevue/button';
export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.vueApp.use(PrimeVue, { ripple: true });
  nuxtApp.vueApp.use(ToastService);
  //   nuxtApp.vueApp.component('Button', Button);
  nuxtApp.vueApp.component('Sidebar', Sidebar);
  nuxtApp.vueApp.component('Divider', Divider);
  nuxtApp.vueApp.component('Password', Password);
  nuxtApp.vueApp.component('Toast', Toast);
  nuxtApp.vueApp.component('Button', Button);
});
