<template>
  <NuxtLayout class="font-alma">
    <Toast></Toast>
    <NuxtLoadingIndicator />
    <NuxtPage />
  </NuxtLayout>
</template>

<script setup lang="ts"></script>

<style lang="scss">
.paper {
  --at-apply: 'auto-container';
  h4 {
    --at-apply: 'text-text-primary text-xl pb-2';
  }
  p {
    --at-apply: 'leading-6 pb-3 text-text-primary-light';
  }
}

.router-link-exact-active {
  --at-apply: 'text-primary';
}
</style>
