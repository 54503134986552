export default defineNuxtPlugin((nuxtApp) => {
  const router = useRouter();

  router.options.scrollBehavior = async (to, from, savedPosition) => {
    if (savedPosition) {
      return savedPosition;
    }

    if (to.hash && to.path == from.path) {
      const el = document.querySelector(to.hash);
      if (el && el.scrollTop) {
        // console.log('el.scrollTop', el.scrollTop);

        return { top: el.scrollTop, left: 0, behavior: 'smooth' };
      }
    }

    return { top: 0, behavior: 'smooth' };
  };
});
